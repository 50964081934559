import $ from 'jquery';
import 'slick-carousel';
import "parsleyjs/dist/parsley.js";

class Dental{
  constructor(){
    var CI = this;
    this.shownTabIndex = 0
    CI.currentTab = 0;
    CI.validate('#dental-form')
    CI.address = ''

    $(".redirect-btn").click(function() {
      $('html, body').animate({
        scrollTop: $("#lead-banner").offset().top
      }, 100);
    });
    
    $(document).ready(function () {
      $('.review-slider').slick({
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 2,
        prevArrow:"<button type='button' class='slick-prev pull-left'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
        nextArrow:"<button type='button' class='slick-next pull-right'><i class='fa fa-angle-right' aria-hidden='true'></i></button>",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              arrows: false,
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              arrows: false,
            }
          }
        ]
      });
    });

    $('.tab-header').click(function(){
      if( this.dataset.flag == 'true') {
        CI.showTab(this)
      } else {
        $('#dental-form').parsley().whenValidate({
          group: 'block-' + CI.shownTabIndex
        })
        .done(()=>{
          console.log(this.dataset.flag)
          CI.nextStep(1)
          if( this.dataset.flag == 'true') {
            CI.showTab(this)
          } else {
            $('#dental-form').parsley().validate({ group: 'block-' + CI.shownTabIndex })
          }
        })
      }
    })

    $('.next-step-radio').on('change', function() {
      CI.nextStep(1)
    });

    $( ".property" ).change(function() {
      $('.towncity').val($(this).find("option:selected").data("city"))
      $('.street1').val($(this).find("option:selected").data("street"))
      $('.county').val($(this).find("option:selected").data("province"))
      $('.houseNumber').val($(this).find("option:selected").data("house-number"))
      $('.street2').val($(this).find("option:selected").data("street2"))
      $('.building').val($(this).find("option:selected").data("building"))
    });

    $('.address').click(function(){
      CI.addr = $('#property').val()
    });

    $('.next-step').click(function() {
      CI.nextStep(1)
    });
    
  }

  showTab(header){
    let CI = this
    let tabs = $('.tab')
    let headers = $('.tab-header')
    CI.currentTab = parseInt(header.dataset.tabIndex)
    CI.shownTabIndex = parseInt(header.dataset.tabIndex)
    tabs.hide()
    headers.removeClass('shadow')
    $(`.${header.dataset.tabHeaderTitle}-div`).show()
    $(`.${header.dataset.tabHeaderTitle}`).addClass('shadow')
  }

  validate(form){
    this.formValidation = $(form).parsley({
      trigger: "focusout",
      errorClass: 'error',
      successClass: 'valid',
      errorsWrapper: '<div class="parsley-error-list"></div>',
      errorTemplate: '<label class="error"></label>',
      errorsContainer (field) {
        if(field.$element.hasClass('approve')){
          return $('.error-checkbox')
        }
        if(field.$element.hasClass('postcode')){
          return $('.postcode-error')
        }
        if(field.$element.hasClass('error-on-button')){
          return $(field.element.closest(".tab").querySelector(".error-box"))
        }
        return field.$element.parent()
      },
    })
    this.validatePostcode()
    this.validateApiPostcode()
  }

  validatePostcode(){
    window.Parsley.addValidator('validPostcode', {
      validateString: function(value){
       return /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/i.test(value);
      },
      messages: {
        en: 'Please Enter Valid UK Postcode',
      }
    });
  }

  validateApiPostcode(){
    var CI = this;
    window.Parsley.addValidator('validapipostcode', {
      validateString: function(value){
       return $.ajax({
          url:`https://api.getAddress.io/find/${$(".postcode").val()}?api-key=NjGHtzEyk0eZ1VfXCKpWIw25787&expand=true`,
          success: function(json){
            if (json.addresses.length > 0) {
              var result = json.addresses
              var adresses = []
               adresses.push( `
                <option
                disabled=""
                selected=""
                >
                Select Your Property
                </option>
              `)
              for (var i = 0; i < result.length; i++) {
                adresses.push( `
                    <option
                    data-street="${result[i].thoroughfare || result[i].line_1}"
                    data-city="${result[i].town_or_city}"
                    data-province="${result[i].county || result[i].town_or_city}"
                    data-street2="${result[i].line_2}"
                    data-building="${result[i].building_name || result[i].sub_building_name || result[i].building_number || result[i].sub_building_number}"
                    data-house-number="${result[i].building_number || result[i].sub_building_number || result[i].building_name || result[i].sub_building_name || result[i].line_1}"
                    >
                    ${result[i].formatted_address.join(" ").replace(/\s+/g,' ')}
                    </option>
                  `)
                }
                $('#property').html(adresses)
                 $(".address-div").remove();
                $(".property-div").show()
              return true
            }else{
              $(".tab").removeClass("in-progress")
              return $.Deferred().reject("Please Enter Valid Postcode");
            }
          },
          error: function(request){
            if (!request.status == 400) {
            }
            console.log(request.statusText)
            request.abort();
            if (request.statusText == "timeout") {
              $(".property-div").remove();
              $(".address-div").show();
            }
          },
          timeout: 5000
        })
      },
      messages: {
         en: 'Please Enter Valid Postcode',
      }
    });
  }

  nextStep(num){
    $('#dental-form').parsley().whenValidate({
      group: 'block-' + this.currentTab
    }).done(()=>{
      var CI = this
      var tabs = $('.tab')
      var tabsHeader = $('.tab-header')
      tabs[CI.currentTab].style.display = 'none'
      $('.heading')[CI.currentTab].classList.remove('shadow')
      CI.currentTab += num
      CI.shownTabIndex = parseInt(CI.currentTab)
      tabsHeader[CI.shownTabIndex].dataset.flag = 'true'
      $('.heading')[CI.currentTab].classList.add('shadow')
      if (CI.currentTab >= tabs.length) {
        console.log("Posted Data succesfully")
      }else{
        if (CI.addr != '') {
          $("#property").val(CI.addr);
        }
        tabs[CI.currentTab].style.display = 'block'
      }
    })
  }

  getData(){
    return{
      service: $("input[name='dental-service']:checked").val(),
      first_name: $('.first_name').val(),
      last_name: $('.last_name').val(),
      email: $('.email').val(),
      contact: $('.contact').val(),
    }
  }
}
export default new Dental()